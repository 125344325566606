<template>
  <div class="softList">
    <div class="softList-box">
      <vxe-table resizable border show-overflow row-key :row-config="{isHover: true}" :data="list" :scroll-y="{enabled: false}" height="auto">
        <vxe-column field="name" title="固件名称" header-align="center"></vxe-column>
        <vxe-column field="version" title="固件版本" header-align="center"  width="220"> </vxe-column>
        <vxe-column field="remark" title="固件说明" header-align="center" width="220"></vxe-column>
        <vxe-column field="deviceTypes" title="设备小类列表" header-align="center">
          <template #default="{ row }">
            <span v-for="val,key in row.deviceTypeNames" :key="key" class="mr5">{{val}}，</span>
          </template>
        </vxe-column>
        <vxe-column  width="250">
          <template #header>
            <Button size="small" type="primary" style="margin-right: 5px" @click="handleEdit(false)">新增</Button>
          </template>
          <template #default="{ row }">
            <Button size="small" type="success" style="margin-right: 5px" @click="handleEdit(true,row)">编辑</Button>
            <Button size="small" type="primary" style="margin-right: 5px" @click="handleRange(row)">更新范围</Button>
            <Button size="small" type="error" style="margin-right: 5px" @click="handleDelete(row)">删除</Button>
          </template>
        </vxe-column>
      </vxe-table>
    </div>
    <softEdit v-model="showCustModal" :item="editItem" :smallTypes='smallTypes' @saved="bigTypeAjax" />
    <softUpdate v-model="showSoftUpdate" :softId='softId' :title='tipsTitle' @saved="bigTypeAjax" />
  </div>
</template>
<script>
import softEdit from './softEdit'
import softUpdate from './softUpdate'
export default {
  name: 'softList',
  components: {
    softEdit,
    softUpdate
  },
  data() {
    return {
      treeIndex: 0,
      tabRefresh: 0,
      softId:'',
      list: [],
      smallTypes: [],
      tipsTitle:'设备固件更新范围信息',
      showCustModal: false,
      showSoftUpdate: false,
      editItem: {
        isAdd: false,
        bigTypeId: -1,
        data: {}
      }
    }
  },
  created: function () {
  },
  props: {
    bigTypeId: {
      type: Number,
      default: -1
    },
  },
  computed: {

  },
  watch: {
    bigTypeId() {
      this.bigTypeAjax();
      this.getSmallTypes();
    }
  },
  mounted: function () {
  },
  destroyed: function () {
  },
  methods: {
    handleDelete(el) {
      this.$Modal.confirm({
        title: "提示",
        content: `确定要删除[${el.name}]吗?`,
        onOk: async () => {
          this.$axios.post(`site/debug/DeleteSoftFile`, { id: el.id }).then(res => {
            if (res.code == 0) {
              this.$Message.info('删除成功');
              this.bigTypeAjax();
            }
          })
        }
      });

    },
    handleEdit(is, com) {
      this.editItem.isAdd = is;
      this.editItem.bigTypeId = this.bigTypeId;
      if (is) {
        this.editItem.data = com;
      }
      this.showCustModal = true
    },
    handleRange(row) {
      this.softId=row.id;
      this.tipsTitle='固件名称:'+row.name+'-----版本:'+row.version
      this.showSoftUpdate = true
    },
    getSmallTypes() {
      this.$axios.post(`site/debug/GetSmallTypes`, { bigTypeId: this.bigTypeId }).then(res => {
        if (res.code == 0) {
          this.$set(this, 'smallTypes', res.data);
        }
      })
    },
    bigTypeAjax() {
      this.$axios.post(`site/debug/QuerySoftList`, { bigTypeId: this.bigTypeId }).then(res => {
        if (res.code == 0) {
          this.$set(this, 'list', res.data);
        }
      })
    },
  },
}
</script>
<style scoped>
.softList {
  height: 100%;
}
.softList-box {
  width: 100%;
  height: 100%;
}
</style>